@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
.wrapper{
     margin: 0%;
    /* padding: 0%; */
    overflow: hidden;
    /* padding-right:250px; */

/* position: relative; */

height: 100%;
}


::-webkit-scrollbar {
    width: 12px;
}
 
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(26, 26, 26, 0.2); 
}
 
::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(27, 27, 27, 0.5); 
}
.sidebar {
  width: 100%;
   overflow-x: hidden;
   overflow-y: auto;
  min-height: 100% !important; 

  /* background-color: #333333; */
  background: #161616;
  /* background-color: #020202; */
  font-family: 'Roboto', sans-serif;
   /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.4); */
  height: 100%;
}

.sidebarList {
  height: 100%;
  width: 100%;
  padding: 5px;
  
  margin-top: 2.3rem;
}

.sidebarList .row {
  width: 100%;
  height: 55px;
  list-style-type: none;
  margin: 0;
  display: flex;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  font-size: medium;
  padding: 0;
}

.row{
  padding: 0%;
  margin: 0%;
}
/* .sidebarList .row:hover{
cursor: pointer;
border-radius: 25px;
background-color: rgb(206, 65, 65);
opacity: 0.5;
} */
.row #icon {
    color: rgb(209, 209, 209);
  flex: 20%;
   display: grid;
  place-items: inherit;
}
.row #title {
     color: rgb(209, 209, 209);
  padding-left: 10px;
  flex: 80%;
  font-size: 11px;
}

.row #icon1 {
  padding: 0%;
     color: white;
  padding-left: 5%;
  flex: 12%;
  display: grid;
  place-items: inherit;
}
.row #title1 {
  padding: 0%;
     color: white;
  flex: 80%;
  font-size: 11px;
}

.active {
  color: white;
  background-color: #bf2e1a;
 


  border-radius: 15px;
 
}
.inactive {
   color: rgb(209, 209, 209);
}

.content{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.one{
  width: 16%;
}
.two{
  
  width:81%;
  /* background-color:#f4f7fa; */
}
.three{
  
  width:97%;
  /* background-color:#f4f7fa; */
}
.four{
  width:3%;
  text-align: center;
  background: #161616;
  height: 100%;

}
.logo{
  width: 48px;
  height: 48px;
  padding:6px;
}

.header-text{
  padding-top: 8px;
}

.header-title{
  font-size: small;
}

.edit-option, .save-option{
  color:#bf2e1a;
 background-color: Transparent;
    background-repeat:no-repeat;
    border: none;
    cursor:pointer;
    overflow: hidden;
    outline:none;
    margin: 4px;
    margin-left: 0%;

}
.edit-option:hover, .save-option:hover{
  color:#bf2e1a;
}

.cancel-option{
  color: #333333;
  background-color: Transparent;
    background-repeat:no-repeat;
    border: none;
    cursor:pointer;
    overflow: hidden;
    outline:none;
    margin: 4px;
}
