.custom-tooltip {
    position: absolute;
    
    height: 40px;
    /* border: 0.01px solid   #8692A1; */
  
    border-radius: 5px;
  }
  
  .custom-tooltip.ag-tooltip-hiding {
    opacity: 0;
  }
  
  .custom-tooltip p {
    margin: 5px;
    position: absolute;
    overflow: hidden;
    pointer-events: none;
    transition: opacity 1s;

    overflow: hidden;
   border: 0.01px solid   #8692A1; 
    white-space: nowrap;
    background-color: white;
    padding:5px;
    border-radius: 5px;

  }
  

  