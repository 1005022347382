* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#notfound {
  position: relative;
  height: 100vh;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 560px;
  width: 100%;
  padding-left: 160px;
  line-height: 1.1;
}

.notfound .notfound-404 {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 110px;
  height: 120px;
  background-image: url("../../../assets/images/error-file-icon.png");
  background-size: cover;
}

.notfound .notfound-404:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(2.4);
  -ms-transform: scale(2.4);
  transform: scale(2.4);
  border-radius: 20px;
  background-color: white;
  z-index: -1;
}

.notfound h1 {
  font-family: "Nunito", sans-serif;
  font-size: 60px;
  font-weight: 650;
  margin-top: 0px;
  margin-bottom: 10px;
  color: #151723;
  text-transform: uppercase;
}

.notfound h2 {
  font-family: "Nunito", sans-serif;
  font-size: 21px;
  font-weight: 400;
  margin: 0;
  padding-bottom: 10px;
  text-transform: uppercase;
  color: #151723;
}

.notfound p {
  font-family: "Nunito", sans-serif;
  color: #87898b;
  font-weight: 400;
}

.notfound a {
  margin-right: 20px;
  font-family: "Nunito", sans-serif;
  display: inline-block;
  font-weight: 700;
  padding: 8px 16px;
  text-decoration: none;
  color: #151723;
  border: 1px solid #151723;
}
.notfound a:hover {
  border: 1px solid #056091;
  color: #056091;
}

@media only screen and (max-width: 767px) {
  .notfound .notfound-404 {
    width: 110px;
    height: 110px;
  }
  .notfound {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 110px;
  }
}
